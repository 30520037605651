
import {
    Chart,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Line as OriginLine } from 'react-chartjs-2';

Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    // Legend,
    Filler
);

const options = (props) => {
    return {
        maintainAspectRatio: false,
        animation: {
            duration: 0
        },
        responsive: true,
        plugins: {
            title: {
                display: false,
            },
            tooltip: {
                enabled: false,

                external: function (context) {
                    // Tooltip Element
                    let tooltipEl = document.getElementById('chartjs-tooltip');

                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip';
                        document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    const tooltipModel = context.tooltip;
                    let label = "";
                    try {
                        label = tooltipModel.dataPoints[0].dataset.label;
                    } catch(e) {
                        console.log(e);
                    }
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.display = "none";
                        return;
                    }

                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }

                    function getBody(bodyItem) {
                        return bodyItem.lines;
                    }

                    // Set Text
                    if (tooltipModel.body) {
                        const titleLines = tooltipModel.title || [];
                        const bodyLines = tooltipModel.body.map(getBody);

                        let innerHtml = `<h2>${label}</h2>`;
                        bodyLines.forEach(function (body, i) {
                            const weight = body.join("").replace(`${label}: `, "");
                            innerHtml += `<p class='num'>${parseFloat(weight).toFixed(1)}</p>`;
                        });
                        innerHtml += `<p class='ja'>kg</p>`;

                        tooltipEl.innerHTML = innerHtml;
                    }

                    const position = context.chart.canvas.getBoundingClientRect();

                    // Display, position, and set styles for font
                    tooltipEl.style.display = "block";
                    tooltipEl.style.position = 'absolute';
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - 45 + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 55 + 'px';
                }
            }
        },
        scales : {
            x: {

            },
            y: {
                offset: 2,
                min: props.min,
                max: props.max,
                ticks: {
                    stepSize: 2
                },
                grid: {
                    display: false,
                },
            }
        },
    }
};

const Line = (props) => {
    return <OriginLine options={options(props)}  { ...props } />
}

export { Line }