import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import Footer from "../parts/footer";
import Register from "../parts/register";
import LoadingItem from "../parts/loading";
import api from "../utils/api";
import moment from "../plugins/moment";
import { postSlack } from '../utils/slack';

window._submitting = false

export default function Main({children}) {
  const { submitting, profile, FIRST_ANSWER_MST } = useSelector((state) => state.data)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  
  useEffect(() => {
    async function init() {
      if(profile) {
        await fetchData();
      }
    }
    init();
  },[profile])

  const fetchData = async () => {
    try {
      await api.updateTable("USER_MST", {USER_CHAT_ID: profile.userId}, {
        USER_CHAT_ID: profile.userId,
        USER_NAME: profile.displayName,
        LAST_LOGIN_AT: moment().format("YYYY-MM-DD HH:mm:ss")
      })
      const [user_mst_data, first_answer_mst_data, user_setting_mst_data, today_report_tra, report_start_obj, report_current_obj] = await Promise.all([
        api.fetchTable("USER_MST", { USER_CHAT_ID: profile.userId }),
        api.fetchTable("FIRST_ANSWER_MST", { USER_CHAT_ID: profile.userId }),
        api.fetchTable("USER_SETTING_MST", { USER_CHAT_ID: profile.userId }),
        api.fetchTable("REPORT_TRA", { USER_CHAT_ID: profile.userId, "DATE_FORMAT(CREATED_AT, '%Y-%m-%d')": moment().format("YYYY-MM-DD") }, false),
        api.fetchSql(`
          SELECT MIN(DATE_FORMAT(CREATED_AT, '%Y-%m-%d')) as CREATED_AT
            FROM REPORT_TRA 
            WHERE USER_CHAT_ID = '${profile.userId}';
        `),
        api.fetchSql(`
          SELECT CURRENT_WEIGHT, DATE_FORMAT(CREATED_AT, '%Y-%m-%d') as CREATED_AT
            FROM REPORT_TRA
            WHERE USER_CHAT_ID = '${profile.userId}'
            ORDER BY CREATED_AT DESC
            LIMIT 1;
        `)
      ])
      if(user_mst_data.status &&
        first_answer_mst_data.status &&
        user_setting_mst_data.status &&
        today_report_tra.status) {
        dispatch({ type: "update_data", data: {
          USER_MST: user_mst_data.data,
          FIRST_ANSWER_MST: first_answer_mst_data.data,
          USER_SETTING_MST: user_setting_mst_data.data,
          TODAY_REPORT_TRA: today_report_tra.data.length > 0 ? today_report_tra.data[0] : null,
          report_start_date: (report_start_obj && report_start_obj.length > 0) ? report_start_obj[0]["CREATED_AT"] : null,
          report_current_obj: (report_current_obj && report_current_obj.length > 0) ? report_current_obj : null
        }});
        setLoading(false);
      }
    } catch (e) {
      const msg = e.stack != undefined ? '-main.js-fetchData-' + e + e.stack : '-main.js-fetchData-' + e;
      await postSlack(msg);
    } finally {
      setLoading(false);
    }
  }

  return (
    loading ? <LoadingItem isSubmitting={false}/> : 
    <div className="wrapper">
      <main className="main">
        {children}
      </main>
      <footer className="footer">
        <Footer/>
      </footer>
      { !FIRST_ANSWER_MST && <Register/>}
      { submitting && <LoadingItem isSubmitting={true}/> }
    </div>
  );
}
