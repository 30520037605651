import postSlack  from './slack';

const getAccessToken = async (code) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      grant_type: 'authorization_code',
      code: code,
      redirect_uri: process.env.REACT_APP_REDIRECT_URI,
      client_id: process.env.REACT_APP_CHANNEL_ID,
      client_secret: process.env.REACT_APP_CHANNEL_SECRET,
    }),
    redirect: 'follow',
  };

  try {
    const response = await fetch('https://api.line.me/oauth2/v2.1/token/', requestOptions);
    const data = await response.json();
    if (data.access_token) {
      const tokenExpirationTime = new Date(Date.now() + data.expires_in * 1000);
      const expiration = new Date(tokenExpirationTime.getTime());
      window.localStorage.setItem(`line_token_${process.env.REACT_APP_TARGET}`, JSON.stringify({
        token: data.access_token,
        refresh_token: data.refresh_token,
        expires_in: expiration
      }));
      return data.access_token;
    } else {
      return null;
    }
  } catch (e) {
    const msg = e.stack != undefined ? '-login.js-getAccessToken' + e + e.stack : '-login.js-getAccessToken' + e;
    await postSlack(msg);
    return null;
  }
};


const getProfile = async (accessToken) => {
  try {
    const response = await fetch('https://api.line.me/v2/profile', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      return await response.json();
    } else {
      return null;
    }
  } catch (e) {
    const msg = e.stack != undefined ? '-login.js-getProfile' + e + e.stack : '-login.js-getProfile' + e;
    await postSlack(msg);
  }
};

const refreshAccessToken = async (refresh_token) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      grant_type: 'refresh_token',
      refresh_token: refresh_token,
      client_id: process.env.REACT_APP_CHANNEL_ID,
      client_secret: process.env.REACT_APP_CHANNEL_SECRET,
    }),
    redirect: 'follow',
  };

  try {
    const response = await fetch('https://api.line.me/oauth2/v2.1/token/', requestOptions);
    const data = await response.json();
    
    if (data.access_token) {
      const tokenExpirationTime = new Date(Date.now() + data.expires_in * 1000); // 有効期限が切れる時刻
      const expiration = new Date(tokenExpirationTime.getTime());
      window.localStorage.setItem(`line_token_${process.env.REACT_APP_TARGET}`, JSON.stringify({
        token: data.access_token,
        refresh_token: data.refresh_token,
        expires_in: expiration
      }));
      return data.access_token;
    }
  } catch (e) {
    const msg = e.stack != undefined ? '-login.js-refreshAccessToken' + e + e.stack : '-login.js-refreshAccessToken' + e;
    await postSlack(msg);
  } 
}

export { getAccessToken, getProfile, refreshAccessToken };