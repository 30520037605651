import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify';
import Router from './router';
import { getAccessToken, getProfile, refreshAccessToken } from "./utils/login";
import LoadingItem from "./parts/loading";
import { generateRandomString } from './utils/functions'
import { json } from 'react-router-dom';
import { postSlack } from './utils/slack';

window._toast = toast
window._submitting = false


const LINE_LOGIN_ENDPOINT = process.env.REACT_APP_LINE_LOGIN_ENDPOINT
const CHANNEL_ID = process.env.REACT_APP_CHANNEL_ID
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI

export default function App() {
  let { profile } = useSelector((state) => state.data)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        let line_obj = localStorage.getItem(`line_token_${process.env.REACT_APP_TARGET}`) ? JSON.parse(localStorage.getItem(`line_token_${process.env.REACT_APP_TARGET}`)) : null;
        // アクセストークンがなければアクセストークンを取得する nullの場合
        if (!line_obj || !line_obj.token) {
          // リフレッシュトークンがない場合 再度LINEログインの処理
          let code = new URLSearchParams(window.location.search).get('code');
          if(!code){
            lineLogin();
          } else {
            const access_token = await getAccessToken(code);
            if (!access_token) {
              lineLogin();
            }
          }
        }
        // 再度lineログインの処理をした後に、再度ローカルストレージから取得
        line_obj = localStorage.getItem(`line_token_${process.env.REACT_APP_TARGET}`) ? JSON.parse(localStorage.getItem(`line_token_${process.env.REACT_APP_TARGET}`)) : null;
        // アクセストークンが有効期限切れの場合は、リフレッシュトークンを使ってアクセストークンを更新
        if (line_obj && line_obj.expires_in) {
          const now = Date.now(); // 現在の時刻を取得する
          const daysLater_28 = new Date(now + 28 * 24 * 60 * 60 * 1000);
          const daysLaterDate_28 = daysLater_28.toISOString();
          if (line_obj.expires_in < daysLaterDate_28) {
            await refreshAccessToken(line_obj.refresh_token);
          }
        }

        if (!profile && line_obj && line_obj.token) {
          fetchData(line_obj.token);
        }
      } catch (e) {
        const msg = e.stack != undefined ? '-app.js-useEffect-' + e + e.stack : '-app.js-useEffect-' + e;
        await postSlack(msg);
        lineLogin();
      }

    })()
  }, [profile])

  const lineLogin = async () => {
    try {
      const params = new URLSearchParams();
      params.append("response_type", "code");
      params.append("client_id",CHANNEL_ID);
      params.append("redirect_uri", REDIRECT_URI);
      params.append("state", generateRandomString());
      params.append("scope", "openid email profile");
      // Lineログイン画面にリダイレクトする
      window.location.href = `${LINE_LOGIN_ENDPOINT}?${params}`
    } catch (e) {
      const msg = e.stack != undefined ? '-app.js-lineLogin-' + e + e.stack : '-app.js-lineLogin-' + e;
      await postSlack(msg);
    }
  };
  
  const fetchData = async (token) => {
    try {
      const data = await getProfile(token);
      if (data) {
        dispatch({
          type: "update_data",
          data: {
            profile: data
          }
        });
        setLoading(false);
      }
    } catch (e) {
      const msg = e.stack != undefined ? '-app.js-fetchData-' + e + e.stack : '-app.js-fetchData-' + e;
      await postSlack(msg);
    }
  };

  if (!profile) {
    return (
      <>
        <LoadingItem />
      </>)
  } else {
      return (
        <>
          <Router />
            <ToastContainer 
              position="top-right"
              autoClose={1000}
              closeOnClick
              theme="colored"
            />
        </>)
  }
}
