import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import LoadingItem from "../parts/loading";
import moment from "../plugins/moment";
import api from "../utils/api";
import { postSlack } from '../utils/slack';

export default function Mypage() {
    const { USER_MST, FIRST_ANSWER_MST, USER_SETTING_MST, report_start_date } = useSelector((state) => state.data)
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true)
    const [form, setForm] = useState({
        term_deadline: "",
        use_start: "",
        drag_names: "",
        renew_dues: "",
        phone: "",
        GOAL_WEIGHT: "",
        GOAL_DUE: "",
        REMIND_HOUR: "",
    });

    useEffect(() => {
        const init = async () => {
            try {
                let db_drag_names = "";
                let db_renew_dues = "";
                let db_phone = "";
                const { data } = await api.fetchDetailTable("CONTRACT_MST", [
                    {
                        field: "USER_CHAT_ID",
                        compare: "=",
                        value: USER_MST.USER_CHAT_ID
                    },
                    {
                        field: "DELETED_AT",
                        compare: "IS",
                        value: null
                    },
                ], false);
                db_drag_names = data.map(x => x.DRAG_NAME).join(", ")
                db_renew_dues = data.map(x => moment(x.RENEW_DUE).format('YYYY-MM-DD')).join(", ")
                db_phone = data.length > 0 ? data[0]["PHONE"] : null;
                const update_form = {
                    ...form,
                    drag_names: db_drag_names,
                    renew_dues: db_renew_dues,
                    phone: db_phone,
                    GOAL_WEIGHT: FIRST_ANSWER_MST.GOAL_WEIGHT,
                    GOAL_DUE: moment(FIRST_ANSWER_MST.GOAL_DUE).format("YYYY-MM-DD"),
                    REMIND_HOUR: USER_SETTING_MST.REMIND_HOUR,
                }
                setForm(update_form)
            } catch (e) {
                const msg = e.stack != undefined ? '-mypage.js-useEffect-' + e + e.stack : '-mypage.js-useEffect-' + e;
                await postSlack(msg);
            } finally {
                setLoading(false);
            }
        }
        init()
    }, [])
    
    const onChangeText = (e) => {
        const { value , name } = e.target 
        setForm({
            ...form,
            [name]: value
        })
    }
    const onChangeSelect = (e) => {
        const { value , name } = e.target 
        setForm({
            ...form,
            [name]: value
        })
    }

    const onSubmitHandler = async (e) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch({ type: "update_submitting", data: true })
        const first_answer_mst_update = {
            USER_CHAT_ID: USER_MST.USER_CHAT_ID,
            GOAL_WEIGHT: form.GOAL_WEIGHT,
            GOAL_DUE: moment(form.GOAL_DUE, "YYYY-MM-DD").format("YYYY-MM-DD HH:mm:ss")
        }
        const user_setting_mst_update = {
            USER_CHAT_ID: USER_MST.USER_CHAT_ID,
            REMIND_HOUR: form.REMIND_HOUR,
        }
        const [first_answer_mst_updated, user_setting_mst_updated] = await Promise.all([
            api.updateTable("FIRST_ANSWER_MST", { USER_CHAT_ID: USER_MST.USER_CHAT_ID }, first_answer_mst_update),
            api.updateTable("USER_SETTING_MST", { USER_CHAT_ID: USER_MST.USER_CHAT_ID }, user_setting_mst_update)
        ])
        if(first_answer_mst_updated && user_setting_mst_updated) {
            window._toast.success("更新されました。");
            dispatch({ type: "update_submitting", data: false });
            dispatch({ type: "update_item", data: {
                key: "FIRST_ANSWER_MST",
                value: {
                    GOAL_WEIGHT: form.GOAL_WEIGHT,
                    GOAL_DUE: first_answer_mst_update
                }
            }});
            dispatch({ type: "update_item", data: {
                key: "USER_SETTING_MST",
                value: user_setting_mst_update
            }});
        }
    }

    const changeStarNumber = (text, num) => {
        if(!text || text.length <= 2) return text;
        const first_text = text.slice(0, text.length - num).split('').map(x => "*");
        const second_text = text.slice(-num);
        return first_text.join("") + second_text;
    }

    return (
        <>
            {   
                loading ? <LoadingItem isSubmitting={true}/> : 
                (
                    <section id="setting">
                        <h1 className="s_title">設定の確認・変更</h1>
                        <form onSubmit={onSubmitHandler} className="cm_form">
                            <div className="cm_frow">
                                <label className="cmf_label">服用開始日</label>
                                <div className="cmf_control">
                                    {/* <input type="date" className="cmf_text" name="use_start" onChange={ e => onChangeText(e) } placeholder="2022/04/01"/> */}
                                    <p className='cmf_text disabled'>{ report_start_date ? moment(report_start_date).format('YYYY-MM-DD') : '' }</p>
                                </div>
                            </div>
                            <div className="cm_frow">
                                <label className="cmf_label">服用中の医薬品</label>
                                <div className="cmf_control">
                                    <p className='cmf_text drag_name disabled'>{ form.drag_names }</p>
                                </div>
                            </div>
                            <div className="cm_frow">
                                <label className="cmf_label">解約期限</label>
                                <div className="cmf_control">
                                    <p className='cmf_text disabled'>{ form.renew_dues }</p>
                                </div>
                            </div>
                            <div className="cm_frow">
                                <label className="cmf_label">電話番号</label>
                                <div className="cmf_control">
                                    <p className='cmf_text disabled'>{ changeStarNumber(form.phone, 2) }</p>
                                </div>
                            </div>
                            <div className="cm_frow">
                                <label className="cmf_label">目標体重 kg</label>
                                <div className="cmf_control">
                                    <input 
                                        required 
                                        defaultValue={form.GOAL_WEIGHT}
                                        type="number" 
                                        step="0.01"
                                        min="10" 
                                        max="150"
                                        className="cmf_text" 
                                        name="GOAL_WEIGHT" 
                                        onChange={ e => onChangeText(e) } 
                                        placeholder="59"/>
                                </div>
                            </div>
                            <div className="cm_frow">
                                <label className="cmf_label">目標期限</label>
                                <div className="cmf_control">
                                    <input 
                                        required
                                        defaultValue={form.GOAL_DUE}
                                        type="date" 
                                        className="cmf_text" 
                                        name="GOAL_DUE" 
                                        onChange={ e => onChangeText(e) } 
                                        placeholder="2022/04/01"/>
                                </div>
                            </div>
                            <div className="cm_frow">
                                <label className="cmf_label">通知時刻</label>
                                <div className="cmf_control">
                                    <input 
                                        required 
                                        defaultValue={form.REMIND_HOUR}
                                        type="time" 
                                        className="cmf_text" 
                                        name="REMIND_HOUR" 
                                        onChange={ e => onChangeText(e) } 
                                        placeholder="20:10"/>
                                </div>
                            </div>
                            <div className='c_act'>
                                <button type="submit" className='ca_submit'>更新</button>
                            </div>
                        </form>
                    </section>
                )
            }
        </>
    );
}