import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import moment from "../plugins/moment";
import api from "../utils/api";
import { postSlack } from "../utils/slack";
import axios from "axios";
const BACKEND_LINE_URL = process.env.REACT_APP_OPERATE_LINE;

Modal.setAppElement("#root");

const init_form = {
  USER_CHAT_ID: null,
  CONTRACT_ID_JSON: [],
  IS_TAKEN: 1,
  CURRENT_WEIGHT: null,
  DOSING_TIME: null,
};

export default function ReportModal({ open, item, toggleModal }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { submitting, USER_MST, TODAY_REPORT_TRA, report_start_date } =
    useSelector((state) => state.data);
  const [form, setForm] = useState(JSON.parse(JSON.stringify(init_form)));
  const [contractMsts, setContractMsts] = useState([]);

  useEffect(() => {
    async function init() {
      onInitMst();
      onInitForm();
    }
    init();
  }, [open, item, toggleModal]);

  const onInitMst = async () => {
    const { data } = await api.fetchDetailTable(
      "CONTRACT_MST",
      [
        {
          field: "USER_CHAT_ID",
          compare: "=",
          value: USER_MST.USER_CHAT_ID,
        },
        {
          field: "DELETED_AT",
          compare: "IS",
          value: null,
        },
      ],
      false
    );
    setContractMsts(data);
  };

  const onInitForm = () => {
    let item_cp = JSON.parse(JSON.stringify(item));
    if (item_cp.hasOwnProperty("DELETED_AT")) delete item_cp.DELETED_AT;
    if (item_cp.hasOwnProperty("UPDATED_AT")) delete item_cp.UPDATED_AT;
    const active_contracts = contractMsts.map((contract_row) => {
      return {
        CONTRACT_ID: contract_row.CONTRACT_ID,
        DRAG_NAME: contract_row.DRAG_NAME,
      };
    });
    const escapedJsonString = JSON.stringify(active_contracts)
      .replace(/[\\"']/g, "\\$&")
      .replace(/\u0000/g, "\\0");

    let update_form = {
      ...JSON.parse(JSON.stringify(init_form)),
      ...item_cp,
      USER_CHAT_ID: USER_MST.USER_CHAT_ID,
      CONTRACT_ID_JSON: escapedJsonString,
    };
    if (update_form.hasOwnProperty("DOSING_TIME")) {
      update_form.DOSING_TIME = moment(update_form.DOSING_TIME).format("HH:mm");
    }
    setForm(update_form);
  };

  const onChaneText = (key, e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setForm({
      ...form,
      [key]: value,
    });
  };
  const getDate = () => {
    const selected_date =
      item && item.hasOwnProperty("CREATED_AT")
        ? moment(item.CREATED_AT)
        : moment();
    return selected_date.format("MM月DD日");
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!form.CURRENT_WEIGHT || !form.DOSING_TIME) return;
    try {
      dispatch({ type: "update_submitting", data: true });
      const report_id = form.REPORT_ID != undefined ? form.REPORT_ID : -1;
      const update_data = {
        ...form,
        DOSING_TIME: `${form.DOSING_TIME}`,
      };
      if (report_id !== -1 && form.hasOwnProperty("CREATED_AT")) {
        delete form.CREATED_AT;
      }
      const result = await api.updateTable(
        "REPORT_TRA",
        { REPORT_ID: report_id },
        update_data
      );

      if (result?.USER_CHAT_ID) {
        const push_message_params = {
          userId: result.USER_CHAT_ID,
        };
        const line_push_message_result = axios.get(BACKEND_LINE_URL, {
          params: push_message_params,
        });
        console.log(line_push_message_result);
      }
      
      if (result) {
        if (
          moment(result.CREATED_AT).format("YYYY-MM-DD") ==
          moment().format("YYYY-MM-DD")
        ) {
          const update_state_data = report_start_date
            ? { TODAY_REPORT_TRA: result }
            : {
                TODAY_REPORT_TRA: result,
                report_start_date: result.CREATED_AT,
              };
          dispatch({
            type: "update_data",
            data: update_state_data,
          });
          navigate("/log?tab=calendar");
        }
        toggleModal();
      }
    } catch (e) {
      const msg =
        e.stack != undefined
          ? "-reportModal.js-onSubmitHandler-" + e + e.stack
          : "-reportModal.js-onSubmitHandler-" + e;
      await postSlack(msg);
    } finally {
      dispatch({ type: "update_submitting", data: false });
    }
  };

  return (
    <>
      <Modal isOpen={open} className="c_modal c_modal_input">
        <div className="cm_head">
          <button onClick={toggleModal} className="cm_close">
            ×
          </button>
          <h2>{getDate()}</h2>
        </div>
        <form onSubmit={onSubmitHandler} className="cm_content">
          {contractMsts && contractMsts.length > 0 && (
            <h3 className="cmc_title">
              {contractMsts.map((x, i) => (
                <span key={i}>{x.DRAG_NAME}</span>
              ))}
            </h3>
          )}
          <div className="cc_form">
            <div className="cf_row">
              <label className="cf_label">体重</label>
              <div className="cfr_control cfr_control--weight">
                <input
                  required
                  type="number"
                  step="0.01"
                  defaultValue={form.CURRENT_WEIGHT}
                  className="cf_text"
                  min="10" 
                  max="150"
                  onChange={(e) => onChaneText("CURRENT_WEIGHT", e)}
                  placeholder="（例）52"
                />
                <p className="cf_suffix">kg</p>
              </div>
            </div>
            <div className="cf_row">
              <label className="cf_label">服用時刻</label>
              <div className="cfr_control cfr_control--admin">
                <input
                  required
                  type="time"
                  defaultValue={form.DOSING_TIME}
                  className="cf_text"
                  onChange={(e) => onChaneText("DOSING_TIME", e)}
                  placeholder="00:00"
                />
              </div>
            </div>
          </div>
          <div className="c_act">
            <button type="submit" disabled={submitting} className="ca_submit">
              送信
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}
