import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import LoadingItem from "../parts/loading";
import { Line } from "../plugins/react-chartjs-2";
import moment from "../plugins/moment";
import api from '../utils/api';
import { postSlack } from '../utils/slack';

export default function Graph() {
    const { USER_MST, FIRST_ANSWER_MST, TODAY_REPORT_TRA } = useSelector((state) => state.data)
    const [tab, setTab] = useState("week");
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function init() {
            fetchData();
        }
        init();
    }, [tab])
    
    const fetchData = async () => {
        try {
            const [sql, items] = itemArgsGnerator(tab, USER_MST.USER_CHAT_ID);
            const result_reports = await api.fetchSql(sql);
            const new_reports = items.map(x => {
                const result_report = result_reports.find(y => y.date_str === x.date_str)
                x.weight = result_report ? result_report.weight : null;
                return x;
            })
            setReports(new_reports);
        } catch (e) {
            const msg = e.stack != undefined ? '-graph.js-fetchData-' + e + e.stack : '-graph.js-fetchData-' + e;
            await postSlack(msg);
        } finally {
            setLoading(false);
        }
    };

    const onClickTab = (e, value) => {
        e.stopPropagation()
        e.preventDefault()
        setTab(value)
    }

    const options = () => {
        const goal = parseFloat(FIRST_ANSWER_MST.GOAL_WEIGHT);
        const start = parseFloat(FIRST_ANSWER_MST.CURRENT_WEIGHT);
        let max = 0;
        let min = goal;

        const data = reports.map(x => {
            if (x.weight) {
                if (max > x.weight) { 
                    max = x.weight;
                }
                if (min < x.weight) { 
                    min = x.weight;
                }
                return x.weight;
            }
        });
        max = max !== 0 ? max + 5 : start + 5;
        min = min == goal ? goal - 15 : min - 15 ;

        let datasets = [
            {
                label: "RESULT",
                data: data,
                borderColor: ["#707070"],
                backgroundColor: '#707070',
                pointStyle: "line"
            },
            {
                label: "GOAL",
                data: reports.map(x => goal),
                borderColor: ["#c1272d"],
                borderDash: [10, 3],
                pointStyle: "line"
            },
        ];
        const res = {
            data: {
                labels: reports.map(x => x.label ),
                datasets: datasets
            },
            min: min,
            max: max,
            goal: goal
        }

        return res;
    }
    return (
        <>  
            {
                loading ? <LoadingItem isSubmitting={true}/> : 
                (
                    <div id="graph">
                        <nav className="g_navs">
                            <ul>
                                <li className={ tab === "week" ? "active" : ""}><a onClick={ e => onClickTab(e, "week")} >1週間</a></li>
                                <li className={ tab === "month" ? "active" : ""}><a onClick={ e => onClickTab(e, "month")}>１ヶ月</a></li>
                                <li className={ tab === "3month" ? "active" : ""}><a onClick={ e => onClickTab(e, "3month")}>３ヶ月</a></li>
                                <li className={ tab === "6month" ? "active" : ""}><a onClick={ e => onClickTab(e, "6month")}>6ヶ月</a></li>
                                <li className={ tab === "year" ? "active" : ""}><a onClick={ e => onClickTab(e, "year")}>１年</a></li>
                            </ul>
                        </nav>
                        <h2 className="g_title">{moment().format("YYYY年MM月DD日")}　体重 <span>{ TODAY_REPORT_TRA ? TODAY_REPORT_TRA.CURRENT_WEIGHT : null }</span>kg</h2>
                        <div className='graph_line'>
                            <Line
                                height={450}
                                { ...options() }
                            />
                        </div>
                    </div>
                )
            }
        </>
    )
}

const itemArgsGnerator = (value, userId) => {
    let sql = "";
    let items = [];
    switch(value) {
        case "week":
            {
                const from = moment().subtract(1, "week").add(1, "day");
                const to = moment();
                const days = to.diff(from, "days");
                sql = `
                    SELECT CURRENT_WEIGHT AS weight, DATE_FORMAT(CREATED_AT, '%Y-%m-%d') AS date_str 
                    FROM REPORT_TRA 
                    WHERE DATE_FORMAT(CREATED_AT, '%Y-%m-%d') >= '${from.format("YYYY-MM-DD")}'
                        AND DATE_FORMAT(CREATED_AT, '%Y-%m-%d') <= '${to.format("YYYY-MM-DD")}' 
                        AND USER_CHAT_ID = '${userId}' 
                    ORDER BY date_str ASC
                `;
                for( let i = 0; i < days + 1; i++) {
                    const date_obj = moment().subtract(1, "week").add(1 + i, "day");
                    items.push({
                        date_str: date_obj.format("YYYY-MM-DD"),
                        label: date_obj.format("DD日"),
                        weight: null,
                    });
                }
            }
            break;
        case "month": 
            {
                const from = moment().subtract(1, 'months');
                const to = moment();
                const days = to.diff(from, 'days');
                sql = `
                    SELECT CURRENT_WEIGHT AS weight, DATE_FORMAT(CREATED_AT, '%Y-%m-%d') AS date_str 
                    FROM REPORT_TRA 
                    WHERE DATE_FORMAT(CREATED_AT, '%Y-%m-%d') >= '${from.format("YYYY-MM-DD")}'
                        AND DATE_FORMAT(CREATED_AT, '%Y-%m-%d') <= '${to.format("YYYY-MM-DD")}' 
                        AND USER_CHAT_ID = '${userId}' 
                    ORDER BY date_str ASC
                `;
                for( let i = 0; i <= days; i++) {
                    const date_obj = moment().subtract(1, 'months').add(i, "day");
                    items.push({
                        date_str: date_obj.format("YYYY-MM-DD"),
                        label: date_obj.format("DD日"),
                        weight: null,
                    });
                }
            }
            break;
        case "3month": 
            {
                const months = 3;
                const from = moment().startOf('month').subtract(months - 1, "month");
                const to = moment().endOf('month');
                sql = `
                    SELECT  MIN(CURRENT_WEIGHT) AS weight, DATE_FORMAT(CREATED_AT, '%Y-%m') AS date_str 
                    FROM REPORT_TRA 
                    WHERE DATE_FORMAT(CREATED_AT, '%Y-%m-%d') >= '${from.format("YYYY-MM-DD")}' 
                        AND DATE_FORMAT(CREATED_AT, '%Y-%m-%d') <= '${to.format("YYYY-MM-DD")}' 
                        AND USER_CHAT_ID = '${userId}' 
                    GROUP BY date_str 
                    ORDER BY date_str ASC
                `;
                for( let i = 0; i < months; i++) {
                    items.push({
                        date_str: moment().startOf('month').subtract(months - 1 -i, "months").format("YYYY-MM"),
                        label: moment().startOf('month').subtract(months - 1 -i, "months").format("MM月"),
                        weight: null,
                    });
                }
            }
            break;
        case "6month": 
            {
                const months = 6;
                const from = moment().startOf('month').subtract(months - 1, "month");
                const to = moment().endOf('month');
                sql = `
                    SELECT MIN(CURRENT_WEIGHT) AS weight, DATE_FORMAT(CREATED_AT, '%Y-%m') AS date_str 
                    FROM REPORT_TRA 
                    WHERE DATE_FORMAT(CREATED_AT, '%Y-%m-%d') >= '${from.format("YYYY-MM-DD")}' 
                        AND DATE_FORMAT(CREATED_AT, '%Y-%m-%d') <= '${to.format("YYYY-MM-DD")}' 
                        AND USER_CHAT_ID = '${userId}' 
                    GROUP BY date_str 
                    ORDER BY date_str ASC
                `;
                for( let i = 0; i < months; i++) {
                    items.push({
                        date_str: moment().startOf('month').subtract(months - 1 -i, "months").format("YYYY-MM"),
                        label: moment().startOf('month').subtract(months - 1 -i, "months").format("MM月"),
                        weight: null,
                    });
                }
            }
            break;
        case "year": 
            {

                const months = 12;
                const from = moment().startOf('month').subtract(months - 1, "month");
                const to = moment().endOf('month');
                sql = `
                    SELECT MIN(CURRENT_WEIGHT) AS weight, DATE_FORMAT(CREATED_AT, '%Y-%m') AS date_str 
                    FROM REPORT_TRA
                    WHERE DATE_FORMAT(CREATED_AT, '%Y-%m-%d') >= '${from.format("YYYY-MM-DD")}' 
                        AND DATE_FORMAT(CREATED_AT, '%Y-%m-%d') <= '${to.format("YYYY-MM-DD")}' 
                        AND USER_CHAT_ID = '${userId}' 
                    GROUP BY date_str 
                    ORDER BY date_str ASC
                `;
                for( let i = 0; i < months; i++) {
                    items.push({
                        date_str: moment().startOf('month').subtract(months - 1 -i, "months").format("YYYY-MM"),
                        label: moment().startOf('month').subtract(months - 1 -i, "months").format("MM月"),
                        weight: null,
                    });
                }
            }
            break;
        default:
            break;
    }
    return [sql, items];
};