import React, {useState, useEffect, useCallback} from 'react';
import { useSelector } from 'react-redux'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import LoadingItem from "../parts/loading";
import moment from "../plugins/moment";
import ReportModal from "./reportModal";
import api from '../utils/api';
import { postSlack } from '../utils/slack';

export default function Calendar() {
    const { USER_MST, TODAY_REPORT_TRA, report_start_date } = useSelector((state) => state.data)
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [selected, setSelected] = useState(null);
    const [missings, setMissings] = useState(null);
    const [loading, setLoading] = useState(true);
    const [range, setRange] = useState({
        start: null,
        end: null,
    });
    const [reports, setReports] = useState([])

    useEffect( () => {
        async function init() {
            fetchData()
        }
        init();
    }, [range,open,edit, TODAY_REPORT_TRA, report_start_date])

    const fetchData = async () => {
        try {
            if (!range.start || !range.end) return;
            const [month_reports, latest_reports] = await Promise.all([
                api.fetchDetailTable("REPORT_TRA", [
                    {
                        field: "DATE_FORMAT(CREATED_AT, '%Y-%m-%d')", 
                        compare: ">=", 
                        value: moment(range.start).format("YYYY-MM-DD")
                    },
                    {
                        field: "DATE_FORMAT(CREATED_AT, '%Y-%m-%d')", 
                        compare: "<", 
                        value: moment(range.end).format("YYYY-MM-DD")
                    },
                    {
                        field: "USER_CHAT_ID", 
                        compare: "=", 
                        value: USER_MST.USER_CHAT_ID
                    },
                ], false),
                api.fetchDetailTable("REPORT_TRA", [
                    {
                        field: "DATE_FORMAT(CREATED_AT, '%Y-%m-%d')", 
                        compare: ">", 
                        value: moment().subtract(2, "weeks").format("YYYY-MM-DD")
                    },
                    {
                        field: "DATE_FORMAT(CREATED_AT, '%Y-%m-%d')", 
                        compare: "<=", 
                        value: moment().format("YYYY-MM-DD")
                    },
                    {
                        field: "USER_CHAT_ID", 
                        compare: "=", 
                        value: USER_MST.USER_CHAT_ID
                    },
                ], false),
            ]);

            if(!(month_reports.status) || !(latest_reports.status)) return;
            let range_start_date = moment(range.start);
            let new_reports = [];
            let new_missings = null;
            if(report_start_date) {
                new_missings = 0;
                const report_start_date_moment = moment(report_start_date);
                if(report_start_date_moment.diff(range_start_date, 'days') > 0) range_start_date = report_start_date_moment

                let range_end_date = moment(range.end)
                range_end_date = range_end_date.format("YYYY-MM-DD") > moment().format("YYYY-MM-DD") ? moment() : range_end_date
                const count = range_end_date.diff(range_start_date, 'days') + 1
                for (let i = 0; i < count; i++) {
                    const date_str = range_start_date.clone().add(i, "day").format("YYYY-MM-DD");
                    const data = month_reports.data.find((x) => moment(x.CREATED_AT).format('YYYY-MM-DD') == date_str);
                    new_reports.push({
                        start: date_str,
                        extendedProps: {
                            data: data ? data : null
                        }
                    })
                }
                const lastest_report_first_date = report_start_date_moment.diff(moment().subtract(13, "days"), 'days') > 0 ? 
                    report_start_date_moment :
                    moment().subtract(13, "days");
                const lastest_count = moment().diff(lastest_report_first_date, 'days') + 1
                for (let i = 0; i < lastest_count; i++) {
                    const date_str = lastest_report_first_date.clone().add(i, "day").format("YYYY-MM-DD");
                    const data = latest_reports.data.find((x) => moment(x.CREATED_AT).format('YYYY-MM-DD') == date_str);
                    if(!data) new_missings++;
                }
            } else {
                new_reports.push({
                    start: moment().format("YYYY-MM-DD"),
                    extendedProps: {
                        data: null
                    }
                });
            }
            setReports(new_reports);
            setMissings(new_missings);
        } catch (e) {
            const msg = e.stack != undefined ? '-calendar.js-fetchData-' + e + e.stack : '-calendar.js-fetchData-' + e;
            await postSlack(msg);
        } finally {
            setLoading(false);
        }
    }

    const toggleModal = () => {
        setOpen(!open);
    }

    const closeEditModal = () => {
        setEdit(false);
        setSelected(null);
    }

    const eventClick = (obj) => {
        let params = {};
        if(obj.event.extendedProps.data) {
            params = JSON.parse(JSON.stringify(obj.event.extendedProps.data));
        } else {
            params = {
                CREATED_AT: moment(obj.event.startStr, "YYYY-MM-DD").format("YYYY-MM-DD HH:mm:ss")
            }
        }
        setSelected(params)
        setEdit(true)
    }

    const calendarDataset = (args) => {
        setRange({
            start: args.startStr,
            end: args.endStr,
        })
    }
    
    const renderEventContent = (obj) => {
        return obj.event.extendedProps.data ? (
            <img src="/icon_done.svg"/>
        ) : (
            <img src="/icon_yet.svg"/>
        )
    }

    const dayCellContent = (obj) => {
        return obj.dayNumberText.replace("日","");
    }

    const renderMissingMessage = () => {
        const report_start_date_moment = moment(report_start_date);
        if (missings == 0) {
            return;
        }
        if(moment().diff(report_start_date_moment, 'days') >= 13) {
            return `直近2週間で${missings}件の\n未入力項目があります。`;
        } else {
            return `服用開始してから${missings}件の\n未入力項目があります。`;
        }
    }

    return (
        <>
            {
                loading ? <LoadingItem isSubmitting={true}/> : 
                (
                    <div id="calendar">
                        {
                            missings != null && (
                                <h2 className="c_title">{ renderMissingMessage() }</h2>
                            )
                        }
                        <div className='c_calendar'>
                            <FullCalendar
                                datesSet={calendarDataset}
                                locale="ja" // 日本語化
                                eventContent={renderEventContent}
                                dayCellContent={dayCellContent}   
                                events={reports}
                                eventClick={eventClick}
                                plugins={[dayGridPlugin]}
                                contentHeight={'auto'}
                                initialView='dayGridMonth'
                                weekends={true}
                                customButtons={{
                                noyet: {
                                    text: "未入力",
                                },
                                done: {
                                    text: "記入済み",
                                },
                                }}
                                headerToolbar={{
                                    left: "prev,title,next",
                                    center: "noyet",
                                    right: "done",
                                }}
                                titleFormat={{
                                year: "numeric",
                                month: "short",
                                }}
                                buttonText={{
                                    prev:     '◀', // <
                                    next:     '▶', // >
                                    prevYear: '&laquo;',  // <<
                                    nextYear: '&raquo;',  // >>
                                    today:    '今日',
                                    month:    '月',
                                    week:     '週',
                                    day:      '日'
                                }}
                            />
                        </div>
                        {
                            !TODAY_REPORT_TRA ? <div className="t_input">
                                <button  
                                onClick={ toggleModal }
                                className="cb_input">今日の記録を開始する</button>
                                {
                                    <ReportModal
                                        open={open}
                                        item={{
                                            CREATED_AT: moment().format("YYYY-MM-DD HH:mm:ss")
                                        }}
                                        toggleModal={toggleModal}
                                    />
                                }
                            </div> : <></>
                        }
                        {
                            selected ? <ReportModal
                                    open={edit}
                                    item={selected}
                                    toggleModal={closeEditModal}
                                /> : <></>
                        }
                    </div>
                )
            }
        </>
    )
}