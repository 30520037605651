import Main from '../layouts/main';
import Index from '../pages/index';
import Log from '../pages/log';
import Mypage from '../pages/mypage';

export const routes = [
    {
      path: "/",
      element: Index,
      layout: Main,
    },
    {
      path: "/log",
      element: Log,
      layout: Main,
    },
    {
      path: "/mypage",
      element: Mypage,
      layout: Main,
    }
]