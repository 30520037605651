import IconLog from "../assets/svg/IconLog";
import IconInput from "../assets/svg/IconInput";
import IconUser from "../assets/svg/IconUser";

export const MENUS = [
    { path: "/", name: "ヘルスログ", icon: <IconLog/> },
    { path: "/log", name: "記録する", icon: <IconInput/> },
    { path: "/mypage", name: "マイページ", icon: <IconUser/> },
];

export const SEXES = [
    { value: 1, text: "男性" },
    { value: 2, text: "女性" },
];

export default { 
    SEXES,
    MENUS
}