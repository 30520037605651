import IconLog from "../assets/svg/IconLog";
import IconInput from "../assets/svg/IconInput";
import { MENUS } from "../utils/constants";
import { Link, useLocation  } from "react-router-dom";

export default function Footer() {
    const location = useLocation();
    const menus = MENUS
    return (
        <>
            <ul className="c_navs">
                {
                    menus.map((x, index) => (
                        <li key={index}>
                            <Link to={x.path} className={ location.pathname == x.path ? "active" : "" }>
                                {x.icon}
                                <span>{x.name}</span>
                            </Link>
                        </li>
                    ))
                }
            </ul>
        </>
    )
}