import React, {useState, useEffect} from 'react';
import  { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import constants from "../utils/constants";
import functions from "../utils/functions";
import api from "../utils/api";
import moment from "../plugins/moment";
import { now } from 'moment';

export default function Register() {
    const { USER_MST } = useSelector((state) => state.data)
    const [step, setStep] = useState(1);
    const [worries, setWorries] = useState([]);
    const today = moment().format("YYYY-MM-DD");
    const hour = moment().format('HH:mm');
    const [form, setForm] = useState({
        SEX: null,
        CURRENT_WEIGHT: null,
        WORRIES_TYPE_ID: null,
        GOAL_WEIGHT: null,
        GOAL_DUE: null,
        REMIND_HOUR: null,
    });
    const [values, setValues] = useState({
        SEX: null,
        CURRENT_WEIGHT: null,
        WORRIES_TYPE_ID: null,
        GOAL_WEIGHT: null,
        GOAL_DUE: today,
        REMIND_HOUR: hour,
    });

    useEffect( () => {
        async function init() {
            let { data } = await api.fetchDetailTable("WORRY_MST", [
                {
                    field: "WORRIES_TYPE_ID", 
                    compare: ">=", 
                    value: 0
                }
              ], false)
              data = data.map(x => { return { value: x.WORRIES_TYPE_ID, text: x.WORRIES_TYPE_NAME }})
              setWorries(data);
        }
        init();
    }, [])
    
    const setKeyAndValue = (key, value) => {
        setForm({
            ...form,
            [key]: value
        })
        const new_step = step + 1
        setStep(new_step)
    }
    const onChaneText = (key, e) => {
        const { value } = e.target
        setValues({
            ...values,
            [key]: value
        })
    }
    const onUpdateValue = async (key) => {
        const new_form = {
            ...form,
            [key]: values[key]
        }
        const new_step = step + 1
        await setForm(new_form)
        await setStep(new_step)
        if(new_step == 7) {
            const first_answer_mst_update = {
                USER_CHAT_ID: USER_MST.USER_CHAT_ID,
                SEX: new_form.SEX,
                CURRENT_WEIGHT: new_form.CURRENT_WEIGHT,
                WORRIES_TYPE_ID: new_form.WORRIES_TYPE_ID,
                GOAL_WEIGHT: new_form.GOAL_WEIGHT,
                GOAL_DUE: new_form.GOAL_DUE
            }
            const user_setting_mst_update = {
                USER_CHAT_ID: USER_MST.USER_CHAT_ID,
                REMIND_HOUR: new_form.REMIND_HOUR,
            }
            const [first_answer_mst_updated, user_setting_mst_updated] = await Promise.all([
                api.updateTable("FIRST_ANSWER_MST", { USER_CHAT_ID: USER_MST.USER_CHAT_ID }, first_answer_mst_update),
                api.updateTable("USER_SETTING_MST", { USER_CHAT_ID: USER_MST.USER_CHAT_ID }, user_setting_mst_update)
            ])
            if(first_answer_mst_updated != undefined && user_setting_mst_updated != null ) {
                setTimeout(() => {
                    window.location.reload()
                }, 1000)
            }
        }
    }

    return (
        <>
            <section id="register">
                <div className="c_faq">
                    <ul>
                        <li>はじめまして！</li>
                        <li>記録してくれた情報をもとに，あなたのダイエットや健康のサポートをします</li>
                        <li>まずはあなたに合ったアドバイスができるように，質問にお答えください！</li>
                        <li>あなたの性別を教えてください</li>
                    </ul>
                </div>
                {
                    step === 1 && (
                        <div className="c_answer">    
                            <ul>
                                {
                                    constants.SEXES.map((x, i) => (
                                        <li onClick={ (e) => setKeyAndValue("SEX", x.value)} key={i}>{x.text}</li>
                                    ))
                                }
                            </ul>
                        </div>
                    )
                }
                { 
                    step >= 2 && (
                        <>
                            <div className="c_result">    
                                <ul>
                                    <li>{functions.getValueFromDic(form.SEX, constants.SEXES)}</li>
                                </ul>
                            </div>
                            <div className="c_faq">
                                <ul>
                                    <li>あなたの体重は何kgですか？</li>
                                </ul>
                            </div>
                        </>
                    ) 
                }
                {
                    step >= 3 && (
                        <>
                            <div className="c_result">    
                                <ul>
                                    <li>{form.CURRENT_WEIGHT}kg</li>
                                </ul>
                            </div>
                            <div className="c_faq">
                                <ul>
                                    <li>ありがとうございます！</li>
                                    <li>記録し続けることで，目標に近づきやすくなります</li>
                                    <li>目標の体重は何kgですか？</li>
                                </ul>
                            </div>
                        </>
                    )
                }
                {
                    step >= 4 && (
                        <>
                            <div className="c_result">    
                                <ul>
                                    <li>{form.GOAL_WEIGHT}kg</li>
                                </ul>
                            </div>
                            <div className="c_faq">
                                <ul>
                                    <li>あなたの目標達成期限はいつになりますか？</li>
                                </ul>
                            </div>
                        </>
                    )
                }
                {
                    step >= 5 && (
                        <>
                            <div className="c_result">    
                                <ul>
                                    <li>{form.GOAL_DUE}</li>
                                </ul>
                            </div>
                            <div className="c_faq">
                                <ul>
                                    <li>以下から最も近いお悩みをお選びください</li>
                                </ul>
                            </div>
                        </>
                    )
                }
                {

                    step == 5 && (
                        <div className="c_answer">    
                            <ul>
                                {
                                    worries.map((x, i) => (
                                        <li onClick={ (e) => setKeyAndValue("WORRIES_TYPE_ID", x.value)} key={i}>{x.text}</li>
                                    ))
                                }
                            </ul>
                        </div>
                    )
                }
                {
                    step >= 6 && (
                        <>
                            <div className="c_result">    
                                <ul>
                                    <li>{functions.getValueFromDic(form.WORRIES_TYPE_ID, worries)}</li>
                                </ul>
                            </div>
                            <div className="c_faq">
                                <ul>
                                    <li>リマインドを通知する時刻を設定してください</li>
                                </ul>
                            </div>
                        </>
                    )
                }
                {
                    step >= 7 && (
                        <>
                        <div className="c_result">    
                                <ul>
                                    <li>{form.REMIND_HOUR}</li>
                                </ul>
                            </div>
                            <div className="c_faq">
                                <ul>
                                    <li>入力は以上です</li>
                                    <li>一緒に目標を達成しましょう！</li>
                                </ul>
                            </div>
                        </>
                    )
                }
                {
                    step === 2 && form.CURRENT_WEIGHT === null && (
                        <form onSubmit={(e) => onUpdateValue("CURRENT_WEIGHT")} className="c_value_input">
                            <input required type="number" step="0.01" min="10" max="150" placeholder="体重を数字で入力" onChange={(e) => onChaneText("CURRENT_WEIGHT", e)} />
                            <button type="submit">送信</button>
                        </form>
                    )
                }
                {
                    step === 3 && form.GOAL_WEIGHT === null && (
                        <form onSubmit={(e) => onUpdateValue("GOAL_WEIGHT")} className="c_value_input">
                            <input required type="number" step="0.01" min="10" max="150" placeholder="体重を数字で入力" onChange={(e) => onChaneText("GOAL_WEIGHT", e)} />
                            <button type="submit">送信</button>
                        </form>
                    )
                }
                {
                    step === 4 && form.GOAL_DUE === null && (
                        <form onSubmit={(e) => onUpdateValue("GOAL_DUE")} className="c_value_input">
                            <input required type="date" value={ values.GOAL_DUE } onChange={(e) => onChaneText("GOAL_DUE", e)} />  
                            <button type="submit">送信</button>
                        </form>
                    )
                }

                {
                    step === 6 && form.REMIND_HOUR === null && (
                        <form onSubmit={(e) => onUpdateValue("REMIND_HOUR")} className="c_value_input">
                            <input required type="time" value={ values.REMIND_HOUR } onChange={(e) => onChaneText("REMIND_HOUR", e)} />
                            <button type="submit">送信</button>
                        </form>
                    )
                }
            </section>
        </>
    )
}