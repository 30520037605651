"use strict";

import axios from "axios";
const slack_token = process.env.REACT_APP_SLACK_TOKEN;
const channel_id = process.env.REACT_APP_SLACK_CHANNEL;
const target = process.env.REACT_APP_TARGET;

export async function postSlack(txt) {
  let bug_msg = `バグ詳細：${txt} \n`;
  let urgency_level = ""
  //危険度を示す色
  let color = '#FFB2AE';
  
  if (target == "prd") {
    bug_msg += '<!channel>';
    urgency_level = "高";
    color = '#FF0000'
  } else if (target == "stg") {
    bug_msg += '<!here>';
    urgency_level = "中"
    color = '#EE817B';
  } else {
    color = '#EE817B';
    urgency_level = "低い";
  }

  const title = `服用管理アプリ[${process.env.REACT_APP_TARGET}] バグ通知 緊急度:${urgency_level}`

  const attachment_obj = [{
    color: color, // 縦線の色
    title: title,
    text: bug_msg,
  }]

  return await axios.request({
    method: 'post',
    url: "/slack_webhook",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${slack_token}`
    },
    data: JSON.stringify({
      channel: channel_id,
      attachments: attachment_obj,
    })
  });
}


export default postSlack;