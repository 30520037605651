import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { routes } from './routes';

export default function Index() {
  return (
    <Router>
      <Routes>
        {
          routes.map((item, index) => {
            const Layout = item.layout
            const Element = item.element ? item.element : null
            return (
              <Route 
                key={index}
                path={item.path} 
                element={
                  <Layout>
                    {
                      Element && <Element/>
                    }
                  </Layout>
                }/>
            )
          })
        }
      </Routes>
    </Router>
  );
};

