import axios from "axios";
import { postSlack } from "./slack";
const BACKEND_URL = process.env.REACT_APP_OPERATEDB;

axios.defaults.withCredentials = true;
axios.defaults.headers = {
  "content-type": "application/json",
};

const quote = (val) => (typeof val === "string" ? `"${val}"` : val);

const getQuery = (table, criteria) =>
  `SELECT * FROM ${table} WHERE ${Object.entries(criteria)
    .map(([field, value]) => `${field}=${quote(value)}`)
    .join(" AND ")}`;

const insertQuery = (table, update) =>
  `INSERT INTO ${table} SET ${Object.entries(update)
    .map(([field, value]) => `${field}=${quote(value)}`)
    .join(", ")}`;

const updateQuery = (table, criteria, update) =>
  `Update ${table} SET ${Object.entries(update)
    .map(([field, value]) => `${field}=${quote(value)}`)
    .join(", ")} WHERE ${Object.entries(criteria)
    .map(([field, value]) => `${field}=${quote(value)}`)
    .join(" AND ")}`;

const getDetailQuery = (table, criterias) =>
  `SELECT * FROM ${table} WHERE ${criterias
    .map(({ field, compare, value }) => `${field} ${compare} ${quote(value)}`)
    .join(" AND ")}`;

const getLatestQuery = (table, primary_key) =>
  `SELECT * FROM ${table} ORDER BY ${primary_key} DESC LIMIT 1`;

export const fetchTable = (table, criteria, single = true) => {
  return new Promise(async (resolve, reject) => {
    try {
      const params = {
        sql: getQuery(table, criteria),
        data: [],
      };
      const { data } = await axios.get(BACKEND_URL, { params: params });
      if (single) {
        const single_data = data.length > 0 ? data[0] : null;
        resolve({
          status: true,
          data: single_data,
        });
      }
      resolve({
        status: true,
        data: data,
      });
    } catch (e) {
      const msg =
        e.stack != undefined
          ? "-api.js-fetchTable-" + e + e.stack
          : "-api.js-fetchTable-" + e;
      await postSlack(msg);
      window._toast.error("エラーが発生しました。");
      reject({
        status: false,
        data: null,
      });
    }
  });
};

export const fetchLatestTable = (table, primary_key) => {
  return new Promise(async (resolve, reject) => {
    try {
      const params = {
        sql: getLatestQuery(table, primary_key),
        data: [],
      };
      const { data } = await axios.get(BACKEND_URL, { params: params });
      resolve({
        status: true,
        data: data[0],
      });
    } catch (e) {
      const msg =
        e.stack != undefined
          ? "-api.js-fetchLatestTable-" + e + e.stack
          : "-api.js-fetchLatestTable-" + e;
      await postSlack(msg);
      window._toast.error("エラーが発生しました。");
      reject({
        status: false,
        data: null,
      });
    }
  });
};

export const fetchDetailTable = (table, criteria, single = true) => {
  return new Promise(async (resolve, reject) => {
    try {
      const params = {
        sql: getDetailQuery(table, criteria),
        data: [],
      };
      const { data } = await axios.get(BACKEND_URL, { params: params });
      if (single) {
        const single_data = data.length > 0 ? data[0] : null;
        resolve({
          status: true,
          data: single_data,
        });
      }
      resolve({
        status: true,
        data: data,
      });
    } catch (e) {
      const msg =
        e.stack != undefined
          ? "-api.js-fetchDetailTable-" + e + e.stack
          : "-api.js-fetchDetailTable-" + e;
      await postSlack(msg);
      window._toast.error("エラーが発生しました。");
      reject(null);
    }
  });
};

export const fetchSql = (sql) => {
  return new Promise(async (resolve, reject) => {
    try {
      const params = {
        sql: sql,
        data: [],
      };
      const { data } = await axios.get(BACKEND_URL, { params: params });
      resolve(data);
    } catch (e) {
      const msg =
        e.stack != undefined
          ? "-api.js-fetchSql-" + e + e.stack
          : "-api.js-fetchSql-" + e;
      await postSlack(msg);
      window._toast.error("エラーが発生しました。");
      reject(null);
    }
  });
};

export const updateTable = (table, criteria, update) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db_data = await fetchTable(table, criteria);
      if (db_data.status) {
        if (db_data.data) {
          const params = {
            sql: updateQuery(table, criteria, update),
            data: [],
          };
          const { data } = await axios.get(BACKEND_URL, { params: params });
          if (data) {
            const result = await fetchTable(table, criteria);
            resolve(result.data);
          }
        } else {
          const params = {
            sql: insertQuery(table, update),
            data: [],
          };
          const { data } = await axios.get(BACKEND_URL, { params: params });
          if (data) {
            if (update.hasOwnProperty("CONTRACT_ID_JSON"))
              delete update.CONTRACT_ID_JSON;
            const result = await fetchTable(table, update);
            resolve(result.data);
          }
        }
      }
      reject(null);
    } catch (e) {
      const msg =
        e.stack != undefined
          ? "-api.js-updateTable-" + e + e.stack
          : "-api.js-updateTable-" + e;
      await postSlack(msg);
      window._toast.error("エラーが発生しました。");
      reject(null);
    }
  });
};

// data: array
export const runQuery = (query) => {
  return new Promise(async (resolve, reject) => {
    try {
      const params = {
        sql: query,
        data: [],
      };
      const { data } = await axios.get(BACKEND_URL, { params: params });
      resolve(data);
    } catch (e) {
      const msg =
        e.stack != undefined
          ? "-api.js-runQuery-" + e + e.stack
          : "-api.js-runQuery-" + e;
      await postSlack(msg);
      window._toast.error("エラーが発生しました。");
      reject(null);
    }
  });
};

export default {
  fetchTable,
  fetchDetailTable,
  fetchLatestTable,
  fetchSql,
  updateTable,
  runQuery,
};
