

export default function Loading({ isSubmitting }) {
    return (
        <div id="loading" className={ isSubmitting ? "submitting" : "" }>
            <div className="spiner">
                <div></div><div></div><div></div><div></div>
            </div>
        </div>
    )
}