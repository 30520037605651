export default function IconInput() {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="24.61" height="24.61" viewBox="0 0 24.61 24.61">
      <g id="Icon_feather-plus-square" data-name="Icon feather-plus-square" transform="translate(1.5 1.5)">
        <path id="Path_6" data-name="Path 6" d="M6.9,3H23.709a3.906,3.906,0,0,1,3.9,3.9V23.709a3.906,3.906,0,0,1-3.9,3.9H6.9a3.906,3.906,0,0,1-3.9-3.9V6.9A3.906,3.906,0,0,1,6.9,3ZM23.709,24.61a.9.9,0,0,0,.9-.9V6.9a.9.9,0,0,0-.9-.9H6.9a.9.9,0,0,0-.9.9V23.709a.9.9,0,0,0,.9.9Z" transform="translate(-4.5 -4.5)" fill="#d3d3d3"/>
        <path id="Path_7" data-name="Path 7" d="M18,23.1a1.5,1.5,0,0,1-1.5-1.5V12a1.5,1.5,0,0,1,3,0v9.6A1.5,1.5,0,0,1,18,23.1Z" transform="translate(-7.195 -5.997)" fill="#d3d3d3"/>
        <path id="Path_8" data-name="Path 8" d="M21.6,19.5H12a1.5,1.5,0,0,1,0-3h9.6a1.5,1.5,0,0,1,0,3Z" transform="translate(-5.997 -7.195)" fill="#d3d3d3"/>
      </g>
    </svg>
  )
}


