import React, {useState, useEffect} from 'react';
import { useSearchParams } from "react-router-dom";
import Graph from "../parts/graph";
import Calendar from "../parts/calendar";

export default function Log() {
    const [tab, setTab] = useState("graph");
    const [searchParams] = useSearchParams();

    useEffect( () => {
        async function init() {
            if(searchParams.get('tab')) {
                setTab(searchParams.get('tab'));
            }
        }
        init();
    }, [])

    const onClickTab = (e, value) => {
        e.stopPropagation();
        e.preventDefault();
        setTab(value);
    };
    return (
        <>
            <section id="health">
                <nav className="h_nav">
                    <ul>
                        <li><a onClick={ e => onClickTab(e, "graph")} className={ tab === "graph" ? "active" : ""}>グラフ</a></li>
                        <li><a onClick={ e => onClickTab(e, "calendar")} className={ tab === "calendar" ? "active" : ""}>カレンダー</a></li>
                    </ul>
                </nav>
                {
                    tab == "graph" && <Graph />
                }
                {
                    tab == "calendar" && <Calendar />
                }
            </section>
        </>
    );
}