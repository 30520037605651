const initialState = {
    token: null,
    submitting: false,
    profile: null,
    report_start_date: null,
    USER_MST: null,
    FIRST_ANSWER_MST: null,
    USER_SETTING_MST: null,
    TODAY_REPORT_TRA: null
};

const Data = (state = initialState, action) => {
    switch (action.type) {
        case 'update_data': {
            const new_state = {
                ...state,
                ...action.data
            };
            return new_state
        }
        case 'update_item': {
            const key = action.data.key
            const value = action.data.value
            const new_state = {
                ...state,
                [key]: {
                    ...state[key],
                    ...value
                }
            };
            return new_state
        }
        case 'update_submitting': {
            return {
                ...state,
                submitting: action.data
            }
        }
        default:
            return state;
    }
}
export default Data;