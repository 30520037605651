export default function IconUser() {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="24.343" height="27.011" viewBox="0 0 24.343 27.011">
      <g id="Icon_feather-user" data-name="Icon feather-user" transform="translate(1.5 1.5)">
        <path id="Path_4" data-name="Path 4" d="M27.343,32a1.5,1.5,0,0,1-1.5-1.5V27.836A3.84,3.84,0,0,0,22.007,24H11.336A3.836,3.836,0,0,0,7.5,27.836V30.5a1.5,1.5,0,0,1-3,0V27.836A6.836,6.836,0,0,1,11.336,21H22.007a6.844,6.844,0,0,1,6.836,6.836V30.5A1.5,1.5,0,0,1,27.343,32Z" transform="translate(-6 -6.493)" fill="#d3d3d3"/>
        <path id="Path_5" data-name="Path 5" d="M17.336,3A6.836,6.836,0,1,1,10.5,9.836,6.844,6.844,0,0,1,17.336,3Zm0,10.672A3.836,3.836,0,1,0,13.5,9.836,3.84,3.84,0,0,0,17.336,13.672Z" transform="translate(-6.664 -4.5)" fill="#d3d3d3"/>
      </g>
    </svg>
  )
}


