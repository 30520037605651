exports.getValueFromDic = (value, items) => {
    const item = items.find(x => x.value == value)
    return item.text
}


const { v4: uuidv4 } = require('uuid');

exports.generateRandomString = ()=> {
    const sessionId = uuidv4();
    return sessionId.toString('base64')
              .replace(/\+/g, '') // '+' を '-' に置換
              .replace(/\//g, '') // '/' を '_' に置換
              .replace(/=+$/, ''); // 末尾の '=' を削除
}
