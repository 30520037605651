import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import moment from "../plugins/moment";
import IconRing from "../assets/svg/IconRing";
import ReportModal from "../parts/reportModal";
import { useNavigate } from "react-router-dom";
import api from "../utils/api";
import LoadingItem from "../parts/loading";
import { postSlack } from '../utils/slack';

export default function Index() {
    const navigate = useNavigate()
    const { USER_MST, FIRST_ANSWER_MST, TODAY_REPORT_TRA, report_start_date } = useSelector((state) => state.data)
    const [open, setOpen] = useState(false);
    const [comment, setComment] = useState(null);
    const [days, setDays] = useState(0);
    const [has_alarm, setHasAlarm] = useState(true)
    const [loading, setLoading] = useState(true)

    useEffect( () => {
        async function init() {
                if(USER_MST) {
                    fetchComment();
                }
            }
        init();
    }, [])

    const fetchComment = async () => {
        try {
            const [taking_type, is_over_500, treatment_period] = await Promise.all([
                get_taking_type(USER_MST.USER_CHAT_ID),
                get_is_over_500(USER_MST.USER_CHAT_ID),
                get_treatment_period(USER_MST.USER_CHAT_ID),
            ]);
            if(taking_type != null && treatment_period != null) {
                let where = [
                    {
                        field: "TAKING_TYPE", 
                        compare: "=", 
                        value: taking_type
                    },
                    {
                        field: "TREATMENT_PERIOD", 
                        compare: "=", 
                        value: treatment_period
                    },
                ];
                if (FIRST_ANSWER_MST?.WORRIES_TYPE_ID) {
                    where.push({
                        field: "WORRIES_TYPE_ID", 
                        compare: "=", 
                        value: FIRST_ANSWER_MST?.WORRIES_TYPE_ID
                    })
                }
                if(taking_type != 3 && is_over_500) {
                    where.push({
                        field: "IS_OVER_500", 
                        compare: "=", 
                        value: is_over_500
                    });
                }
                const { data } = await api.fetchDetailTable("ADVICE_MST", where);
                if(data) {
                    const sql = `
                        SELECT *
                            FROM COMMENT_CONTENT_TRA AS CCT
                            WHERE ADVICE_ID = ${data.ADVICE_ID}
                        ORDER BY RAND()
                        LIMIT 1;
                    `;
                    const comments = await api.fetchSql(sql);
                    if(comments.length > 0) {
                        const comment = comments[0];
                        setComment(comment.COMMENT_CONTENT);
                    }
                }
            }
        } catch (e) {
            const msg = e.stack != undefined ? '-index.js-fetchComment-' + e + e.stack : '-index.js-fetchComment-' + e;
            await postSlack(msg);
        } finally {
            setLoading(false);
        }
    }

    const get_taking_type = (USER_CHAT_ID) => {
        return new Promise(async (resolve, reject) => {
            const result = await api.fetchDetailTable("REPORT_TRA", [
                {
                    field: "DATE_FORMAT(CREATED_AT, '%Y-%m-%d')", 
                    compare: ">", 
                    value: moment().subtract(1, "week").format("YYYY-MM-DD")
                },
                {
                    field: "DATE_FORMAT(CREATED_AT, '%Y-%m-%d')", 
                    compare: "<=", 
                    value: moment().format("YYYY-MM-DD")
                },
                {
                    field: "USER_CHAT_ID", 
                    compare: "=", 
                    value: USER_CHAT_ID
                },
            ], false)
            if(result) {
                const items = result.data;
                if(items.length >= 6) {
                    resolve(1);
                } else if(items.length >= 3) {
                    resolve(2);
                } else  {
                    resolve(3);
                }
            }
            resolve(null);
        });
    };

    const get_is_over_500 = (USER_CHAT_ID) => {
        return new Promise(async (resolve, reject) => {
            const one_week_ago = moment().subtract(1, "week").format("YYYY-MM-DD")
            const sql = `
                    SELECT *
                        FROM REPORT_TRA
                        WHERE DATE_FORMAT(CREATED_AT, '%Y-%m-%d') <= '${one_week_ago}' 
                            AND USER_CHAT_ID = '${USER_CHAT_ID}'
                    ORDER BY CREATED_AT DESC
                    LIMIT 1;
                `;
            const result = await api.fetchSql(sql);
            if(result && result.length > 0) {
                const one_week_ago_item = result[0];
                if(TODAY_REPORT_TRA) {
                    if(one_week_ago_item.CURRENT_WEIGHT - TODAY_REPORT_TRA.CURRENT_WEIGHT > 0.5) {
                        resolve(1);
                    } else {
                        resolve(0);
                    }
                } else {
                    resolve(0);
                }
            }
            resolve(null);
        });
    };

    const get_treatment_period = () => {
        return new Promise(async (resolve, reject) => {
            if(report_start_date) {
                const first_moment = moment(report_start_date);
                const days = moment().diff(first_moment, "days");
                setDays(days);
                await reloadHasAlarm(days);
                if(days >= 15 * 7 ) { resolve(4); } 
                else if(days >= 8 * 7 ) { resolve(3); }
                else if(days >= 1 * 7 ) { resolve(2); }
                else { resolve(1); }
            } else {
                setDays(0);
                resolve(1);
            }
        });
    };

    const toggleModal = () => {
        setOpen(!open);
    }

    const getUserName = () => {
        return USER_MST ? USER_MST.USER_NAME : null
    }

    const getChangedComment = (text) => {
        if(text) {
            text = text.replaceAll("${name}", USER_MST.USER_NAME);
            text = text.replaceAll("${day}", days);
            return text;
        } 
        return null;
    }

    const goAlarm = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            if (has_alarm) {
                navigate("/log?tab=calendar");
            }
        } catch (e) {
            const msg = e.stack != undefined ? '-index.js-goAlarm-' + e + e.stack : '-index.js-goAlarm-' + e;
            await postSlack(msg);
        }
    }

    const reloadHasAlarm = async (days) => {
        try {
            const week_reports = await api.fetchDetailTable("REPORT_TRA", [
                {
                    field: "DATE_FORMAT(CREATED_AT, '%Y-%m-%d')", 
                    compare: ">", 
                    value: moment().subtract(1, "week").format("YYYY-MM-DD")
                },
                {
                    field: "DATE_FORMAT(CREATED_AT, '%Y-%m-%d')", 
                    compare: "<=", 
                    value: moment().format("YYYY-MM-DD")
                },
                {
                    field: "USER_CHAT_ID", 
                    compare: "=", 
                    value: USER_MST.USER_CHAT_ID
                },
            ], false);

            if (week_reports.data.length < days) { 
                setHasAlarm(true);
            } else {
                setHasAlarm(false);
            }
        } catch (e) {
            const msg = e.stack != undefined ? '-index.js-reloadHasAlarm-' + e + e.stack : '-index.js-reloadHasAlarm-' + e;
            await postSlack(msg);
        }
        
    }


    return (
        <>
            {   loading ? <LoadingItem isSubmitting={true}/> : 
                (
                    <section id="top">
                        <div className="c_meta">
                            <span className="cm_date">{moment().format('MM月DD日(dddd)')}</span>
                            <div
                                className="cm_alarm"
                                onClick={ (e) => goAlarm(e) }
                            >
                                <IconRing/>
                                <span className={has_alarm ? "has_alarm" : ""}></span>
                            </div>
                        </div>
                        <h1 className="t_title">{`${getUserName()}さん`}</h1>
                        <div className="t_message">
                            <p className="tm_text">{ getChangedComment(comment) }</p>
                            <img src="/staff.png" className="tm_media" alt=""/>
                        </div>
                        {
                            <div className="t_input">
                                <button  
                                disabled={ TODAY_REPORT_TRA }
                                onClick={ toggleModal }
                                className="cb_input">{!TODAY_REPORT_TRA ? "今日の記録を開始する" : "本日の記録は完了しました"}</button>
                                {
                                    <ReportModal
                                        open={open}
                                        item={{ 
                                            CREATED_AT: moment().format("YYYY-MM-DD HH:mm:ss") 
                                        }}
                                        toggleModal={toggleModal}
                                    />
                                }
                            </div>
                        }
                    </section>
                )
            }
        </>
    );
}